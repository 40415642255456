<template>
  <v-btn
    v-if="createCreateChild"
    icon
    color="grey darken-3"
    class="mr-2"
    @click="createChild"
  >
    <v-icon>
      fa fa-plus
    </v-icon>
  </v-btn>
</template>

<script lang="babel" type="text/babel">
import treeNodeAction from '@/components/tree/nodeAction/treeNodeAction'
export default {
  mixins: [treeNodeAction],
  methods: {
    async createChild() {
      this.treeActions.createChild(this.item.id)
    },
  },
  computed: {
    treeActions() {
      return this.$store.getters[`tree/${this.treeKey}/actions`]
    },
    createCreateChild() {
      if(!this.hasWritePermission) return false
      if(!this.treeConfigActions) return true
      if(typeof this.treeConfigActions.create != 'function') return true
      return this.treeConfigActions.create(this.item, this.item.depth)
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>